import { OrgMemberInfo } from '@propelauth/react';
import { type ClassValue, clsx } from 'clsx';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';

import {
  displayDefaultToast,
  displayErrorToast,
} from '~/components/ui/use-toast';
import {
  Cwe,
  DataElementSensitivity,
  IdName,
  Owasp,
  Repository,
  ValueLabel,
  ValueLabelUrl,
  VulnerabilitySeverity,
  VulnerabilityStatus,
} from '~/ts-types';

import { currentOrganizationIdAtom, jotaiStore } from './jotai-atoms';

//
// CN is a utility function that merges tailwind and clsx format classNames
//
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

//
// Capitalize the first letter of a string
//
export function capitalizeFirstLetter(val: string) {
  return val.charAt(0).toUpperCase() + val.slice(1);
}

//
// Capitalize the first letter and return only that character
//
export function stringFirstLetterUppercase(val: string) {
  return val.charAt(0).toUpperCase();
}

//
// takes an ISO date string and returns a string like 'January 1, 2000' depending on the format chosen
//
export function formatIsoDate(isoDate: string, format = DateTime.DATE_FULL) {
  if (!isoDate) {
    return '';
  }
  return DateTime.fromISO(isoDate).toLocaleString(format);
}

//
// Create a list of CWE and OWASP items from the metadata based on provided CWE and OWASP values arrays
//
export function createCweOwaspList(
  cwe: Cwe[],
  owasp: Owasp[],
  cweMetadata: ValueLabelUrl<Cwe>[],
  owaspMetadata: ValueLabelUrl<Owasp>[],
) {
  const result: ValueLabelUrl<Cwe | Owasp>[] = [];
  cwe.forEach((item) => {
    const metadataItem = cweMetadata.find((meta) => meta.value === item);
    if (metadataItem) {
      result.push(metadataItem);
    }
  });
  owasp.forEach((item) => {
    const metadataItem = owaspMetadata.find((meta) => meta.value === item);
    if (metadataItem) {
      result.push(metadataItem);
    }
  });
  return result;
}

//
// SORTING FN: Vulnerability Severity
//
export function vulnerabilitySeveritySortingFn(
  severityA: VulnerabilitySeverity,
  severityB: VulnerabilitySeverity,
) {
  const severityWeights: Record<VulnerabilitySeverity, number> = {
    critical: 1,
    medium: 2,
    low: 3,
  };
  return severityWeights[severityA] - severityWeights[severityB];
}

//
// SORTING FN: Data element sensitivity
//
export function dataElementSensitivitySortingFn(
  sensitivityA: DataElementSensitivity,
  sensitivityB: DataElementSensitivity,
) {
  const sensitivityWeights: Record<DataElementSensitivity, number> = {
    critical: 1,
    medium: 2,
    low: 3,
  };
  return sensitivityWeights[sensitivityA] - sensitivityWeights[sensitivityB];
}

//
// SORTING FN: Vulnerability Status
//
const vulnerabilityStatusWeight = (status: VulnerabilityStatus) => {
  if (status === 'closed') {
    return 3;
  } else if (status === 'ignored') {
    return 2;
  } else {
    return 1;
  }
};
export function vulnerabilityStatusSortingFn(
  statusA: VulnerabilityStatus,
  statusB: VulnerabilityStatus,
) {
  return (
    vulnerabilityStatusWeight(statusA) - vulnerabilityStatusWeight(statusB)
  );
}

//
// SORTING FN: Repository name
//
export function repositorySortingFn(repoA: IdName, repoB: IdName) {
  return repoA.name > repoB.name ? 1 : repoA.name < repoB.name ? -1 : 0;
}

//
// Programming language detection based on filename
//
// this maps to supported syntax highlighting types from this link:
// https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/HEAD/AVAILABLE_LANGUAGES_HLJS.MD
const languageExtensionMapping: { [extension: string]: string } = {
  cs: 'csharp',
  go: 'go',
  // no official support for graphql syntax highlighting
  graphql: 'csharp',
  gql: 'csharp',
  java: 'java',
  js: 'javascript',
  jsx: 'javascript',
  ts: 'typescript',
  tsx: 'typescript',
  json: 'json',
  py: 'python',
  rb: 'ruby',
  sql: 'sql',
  yaml: 'yaml',
  yml: 'yaml',
};
export function getProgrammingLanguageType(filename: string) {
  const fileExtensionRegex = /\.([a-zA-Z0-9]+)$/; // Regex to extract file extension
  const match = filename.match(fileExtensionRegex);
  if (match) {
    const extension = match[1].toLowerCase(); // Get the extension in lowercase
    return languageExtensionMapping[extension] || 'shell';
  } else {
    return 'shell'; // No file extension found return shell as default
  }
}

//
// Get object keys as array but strongly TS typed
//
export function getObjectKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

//
// Last part of an URL which is usually the filename, extension and possible line number
//
export function extractFileInfoFromUrl(fileUrl: string) {
  // 1. Split the URL by '/' to isolate the relevant parts
  const urlParts = fileUrl.split('/');
  // 2. Return the last part, which usually contains filename and line number
  return urlParts.pop();
}

//
// Object has no own properties
//
export function isEmptyObject(object: object) {
  return Object.keys(object).length === 0;
}

//
// Parse the error which is and unknown type and return always a string
//
export function getErrorMessage(error: unknown) {
  if (error instanceof Error) {
    return error.message;
  }
  if (typeof error === 'object' && error !== null && 'message' in error) {
    return error.message as string;
  }
  if (typeof error === 'string' && error.length > 0) {
    return error;
  }
  return 'An unknown error occurred.';
}

//
// Compare if two arrays of strings contains the same elements
//
export function areArraysEqual(arr1: string[], arr2: string[]): boolean {
  // Handle arrays of different lengths
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Create sets for efficient comparison
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  // Check if all elements from set1 exist in set2 and vice-versa
  return (
    [...set1].every((item) => set2.has(item)) &&
    [...set2].every((item) => set1.has(item))
  );
}

//
// Email Validation
//
export function isValidEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

//
// Add the string if not present, remove if present
//
export function toggleStringInArray(array: string[], stringToToggle: string) {
  const index = array.indexOf(stringToToggle);

  if (index !== -1) {
    // Remove if present
    return array.filter((item, idx) => idx !== index);
  } else {
    // Add if not present
    return [...array, stringToToggle];
  }
}

//
// Execute behind the scenes download of a file and clean up itself after the download
//
export function downloadFile(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);

  // Create a hidden anchor element
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.style.display = 'none'; // Ensure it's hidden

  // Add to the DOM, trigger download, then remove
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Release the object URL
  URL.revokeObjectURL(url);
}

//
// Get the current organization ID from the stored value or the first organization alphabetically
//
export function getStoredOrganizationId(organizations: OrgMemberInfo[]) {
  if (!organizations || organizations.length === 0) {
    throw new Error('No organizations found');
  }

  const storedOrgId = jotaiStore.get(currentOrganizationIdAtom);

  if (storedOrgId) {
    return storedOrgId;
  }

  // Initialize the current organization ID to the first organization alphabetically
  const sortedOrganizationId = organizations.sort((a, b) =>
    a.orgName.localeCompare(b.orgName),
  )[0].orgId;
  jotaiStore.set(currentOrganizationIdAtom, sortedOrganizationId);

  return sortedOrganizationId;
}

//
// Copy text to clipboard
//
export async function copyTextToClipboard(
  text: string,
  successMessage?: string,
) {
  try {
    await navigator.clipboard.writeText(text);
    if (successMessage) {
      displayDefaultToast(successMessage);
    }
  } catch (error) {
    displayErrorToast(error);
  }
}

//
// Crop a string to a maximum length then add '...' at the end
//
export function cropString(input: string, maxLength = 50) {
  if (input.length <= maxLength) {
    return input;
  }
  return input.slice(0, maxLength - 3) + '...';
}

//
// Check if a value is a true object but not an array or null which are also objects
//
export function isObject(value: unknown) {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

//
// Tranform an array of objects of type [{value,label}, {value,label}] to a single object of type {value:label, value:label, ....}
//
export function flatValueLabelArray<T extends string>(
  arrayInput: Array<ValueLabel<T>>,
) {
  return arrayInput.reduce(
    (result, item) => {
      result[item.value] = item.label;
      return result;
    },
    {} as Record<T, string>,
  );
}

//
// Transform an array of strings to a query parameter string of the form 'ids=id1&ids=id2&ids=id3'
//
export function transformArrayToIdsQueryParams(ids: string[]) {
  return ids.map((id) => `ids=${encodeURIComponent(id)}`).join('&');
}

//
// Format a string to be used as a URL slug or Id
//
export function formatStringToSlug(input: string) {
  return input.trim().toLowerCase().replace(/\s+/g, '-');
}

//
// Transform an array of objects to a ValueLabel array
//
export function transformObjectArrayToValueLabelArray<
  T extends object,
  K extends keyof T,
>(input: T[], valueKey: K, labelKey: K) {
  return input.map((item) => ({
    value: item[valueKey],
    label: item[labelKey],
  }));
}

//
// Toggle a ValueLabel in an Array<ValueLabel>
//
export function toggleValueLabelInArray<T = string>(
  array: ValueLabel<T>[],
  option: ValueLabel<T>,
) {
  return array.find((opt) => opt.value === option.value)
    ? array.filter((opt) => opt.value !== option.value)
    : [...array, option];
}

//
// Generate a query parameter string for a repository and branch
//
export function generateRepoBranchQueryParams(
  repos: Repository[],
  repoSelected = '',
) {
  let rParam = '';
  let bParam = '';
  let queryParams = '';

  // If there is only one repository, select it by default
  if (!repoSelected && repos.length === 1) {
    rParam = repos[0].name;
  }

  // If repoSelected is provided, select it
  if (repoSelected) {
    rParam = repoSelected;
  }

  if (rParam) {
    const selectedRepo = repos.find((repo) => repo.name === rParam);
    const repoSelectedBranches = selectedRepo?.branches || [];

    if (repoSelectedBranches.length === 1) {
      bParam = repoSelectedBranches[0].name;
    }
    if (repoSelectedBranches.some((branch) => branch.name === 'main')) {
      bParam = 'main';
    }
    if (repoSelectedBranches.some((branch) => branch.name === 'master')) {
      bParam = 'master';
    }
  }

  if (rParam) {
    queryParams = `repository=${encodeURIComponent(rParam)}`;
    if (bParam) {
      queryParams += `&branch=${encodeURIComponent(bParam)}`;
    }
  }

  return queryParams;
}

//
// Generate utils for clientLoaders url manipulation
//
export const DEFAULT_PAGE_SIZE = 20;
export function generateUrlUtils(requestUrl: string) {
  const url = new URL(requestUrl);
  const hasPaginationParams =
    url.searchParams.has('limit') && url.searchParams.has('offset');
  const hasSortingParam = url.searchParams.has('sortBy');
  const hasRepoBranchParams =
    url.searchParams.has('repository') && url.searchParams.has('branch');
  const getParsedSearchParams = (url: URL) => url.searchParams.toString() || '';

  return {
    url,
    getParsedSearchParams,
    hasPaginationParams,
    hasSortingParam,
    hasRepoBranchParams,
  };
}

//
// Parse the sortBy query parameter
//
export function parseSortByParam(sortByParam: string | null) {
  if (!sortByParam) {
    return null;
  }
  const desc = sortByParam.startsWith('-');
  const id = desc ? sortByParam.slice(1) : sortByParam;
  return { id, desc };
}

//
// Convert an array of strings to a gitignore pattern format
//
export function arrayToGitignoreFormat(lines: string[]) {
  if (!lines || lines.length === 0) {
    return '';
  }
  return lines.join('\n');
}

//
// Convert from gitignore pattern format to an array of strings
//
export function gitignoreFormatToArray(text: string): string[] | null {
  // Split the text into an array by new lines and trim each line
  const lines = text
    .split('\n')
    .map((line) => line.trim())
    .filter((line) => line !== ''); // Exclude empty strings;

  // Check for any invalid lines (lines containing spaces)
  for (const line of lines) {
    if (line.includes(' ')) {
      return null; // Return null if any line contains spaces
    }
  }

  // Return the valid array of trimmed lines
  return lines;
}

//
// Progress calculation
//
export function calculateProgress(
  total: number,
  progressToTotal: number,
): number {
  if (total === 0) {
    return 0; // Prevent division by zero
  }
  return Math.min((progressToTotal / total) * 100, 100); // Ensure it doesn't exceed 100%
}
